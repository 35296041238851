var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('validation-observer',{ref:"cloneForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"cloneForm",attrs:{"form":_vm.$refs.cloneForm,"disabled":invalid},on:{"submit":_vm.submit}},[_c('n-input',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"departmentId",fn:function(item){return [_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":item.field.label,"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),_c('n-async-single-select',{ref:item.field.key,class:errors.length > 0 ? 'is-invalid' : null,attrs:{"query":{
                    company_id: _vm.data.companyId,
                  },"name":item.field.key,"repository":item.field.repository,"selection-key":item.field.selectionKey,"selection-label":item.field.selectionLabel},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"jobDetailsEn",fn:function(){return [_c('div',{staticClass:"pt-2"},[_c('b-row',[_vm._l((_vm.data.jobDetailsEn),function(detailEn,idx){return _c('b-col',{key:("detailEn-" + idx),attrs:{"lg":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":((detailEn.title) + "-" + idx),"name":_vm.$t('field.titleEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.titleEn")))]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.titleEn'),"autocomplete":"off"},model:{value:(detailEn.title),callback:function ($$v) {_vm.$set(detailEn, "title", $$v)},expression:"detailEn.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":((detailEn.description) + "-" + idx),"name":_vm.$t('field.descriptionEn'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.descriptionEn")))]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":detailEn.description,"config":detailEn.config},model:{value:(detailEn.description),callback:function ($$v) {_vm.$set(detailEn, "description", $$v)},expression:"detailEn.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","align-self":"end"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.jobDetailsEn.length > 1),expression:"data.jobDetailsEn.length > 1"}],staticClass:"mb-2 mt-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeDetailEn(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),_c('b-col',[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreDetailEn}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addDetail")))])],1)],1)],2)],1)]},proxy:true},{key:"jobDetailsKm",fn:function(){return [_c('div',{staticClass:"pt-2"},[_c('b-row',[_vm._l((_vm.data.jobDetailsKm),function(detailKm,idx){return _c('b-col',{key:("detailKm-" + idx),attrs:{"lg":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":((detailKm.title) + "-" + idx),"name":_vm.$t('field.titleKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.titleKm"))+" ")]),_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('field.titleKm'),"autocomplete":"off"},model:{value:(detailKm.title),callback:function ($$v) {_vm.$set(detailKm, "title", $$v)},expression:"detailKm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":((detailKm.description) + "-" + idx),"name":_vm.$t('field.descriptionKm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t("field.descriptionKm")))]),_c('ckeditor',{class:errors.length > 0 ? 'ck-invalid' : null,attrs:{"tag-name":"textarea","name":detailKm.description,"config":detailKm.config},model:{value:(detailKm.description),callback:function ($$v) {_vm.$set(detailKm, "description", $$v)},expression:"detailKm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","align-self":"end"}},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.jobDetailsKm.length > 1),expression:"data.jobDetailsKm.length > 1"}],staticClass:"mb-2 mt-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeDetailKm(idx)}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])],1)],1)],1),_c('hr',{staticClass:"mb-2"})],1)}),_c('b-col',[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addMoreDetailKm}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("button.addDetail")))])],1)],1)],2)],1)]},proxy:true}],null,true),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', _vm.resource))?_c('n-button-save-option',{ref:"btnSubmit",attrs:{"loading":_vm.loading,"resource":_vm.resource,"route":_vm.route,"hide-create":true},on:{"submit":_vm.submit,"save":_vm.save}}):_vm._e(),_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary","loading":_vm.loading},on:{"submit":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }